<template>
    <b-card title="Compressor Warranty">
        <b-col cols="12">

            <b-row class="mb-2">
                <b-col cols="4">
                    <b-button v-if="permission.add" @click="toCreateCompressor" variant="success">
                        Add Compressor Warranty
                    </b-button>
                </b-col>
                <b-col cols="4"/>
                <b-col cols="4">
                <b-form-input
                    @input="pullCompressorWarranty"
                    v-model="search"
                    type="search"
                    placeholder="Type to Search"
                />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="table-responsive">
                    <b-table
                        :fields="fields"
                        :items="compressorWarranty"
                        :busy.sync="tableLoading"
                        show-empty
                    >
                        <template #cell(actions)="row">
                            <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                                <template #button-content>
                                    <feather-icon size="1x" icon="MenuIcon" />
                                </template>
                                <b-dropdown-item style="text-align:center" v-if="permission.edit">
                                    <b-button  variant="outline-primary" @click="toEditWarranty(row.item._id)">
                                        <feather-icon icon="EditIcon"/>
                                    </b-button>
                                </b-dropdown-item>
                                <b-dropdown-item style="text-align:center" v-if="permission.delete">
                                    <b-button variant="danger" @click="doDeleteCompressorWarranty(row.item._id)">
                                        <feather-icon icon="TrashIcon"/>
                                    </b-button>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="onPageChange"
                    v-model="currentPage"
                    style="float: right"
                />
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import {userAccess} from '@/utils/utils'

export default {
    data() {
        return {
            fields: [
                { key: 'category', label: 'Category', filterable: true, sortable: true },
                { key: 'month', label: 'Month', filterable: true, sortable: true },
                { key: 'type', label: 'Type', filterable: true, sortable: true },
                { key: 'actions', label: 'Action' }
            ],
            compressorWarranty: [],
            search: '',
            currentPage: 1,
            perPage: 5,
            totalRows: 1,
            tableLoading: false,
            modalDelete:false,
            donwloadModal:false,
            selectPostal:''
        }
    },
    mounted() {
        
    },
    methods: {
        ...mapActions({
            listCompressorWarrantyByQuery: 'warranty/getCompressorWarrantyByQuery',
            listCompressorWarranty: 'warranty/listCompressorWarranty',
            deleteCompressorWarranty: 'warranty/deleteCompressorWarranty',
        }),
        pullCompressorWarranty() {
            this.listCompressorWarrantyByQuery({
                page: this.currentPage, 
                entry: this.perPage,
                category:this.search})
                .then((response) => {
                    console.log('pull response ',response)
                    this.compressorWarranty = response.data.data;
                    this.processMetadata(response.data.metadata)
                    return response.data;
                })
        },
        toCreateCompressor() {
            this.$router.push('/warranty/compressor-warranty/add')
        },
        doDeleteCompressorWarranty(id) {
            this.deleteCompressorWarranty(id).then(() => {
                this.pullCompressorWarranty();
            })
        },
        toEditWarranty(id) {
            this.$router.push(`/warranty/compressor-warranty/edit/${id}`)
        },
        searchByCategory(){
            this.currentPage=1;
            this.pullCompressorWarranty()
        },
        onPageChange(page) {
            this.currentPage=page;
            this.pullCompressorWarranty()
        },
        processMetadata(metadata) {
            let { totalData } = metadata;
            console.log('total data ',totalData)
            this.totalRows = totalData;
            this.tableLoading = false;
        },
    },
    computed: {
        permission() {
            return userAccess("Compressor Warranty", "warranty_master_menu");
        },
    },
    created() {
        this.pullCompressorWarranty();
        document.title = 'Compressor Warranty | RSP'
    },
}
</script>